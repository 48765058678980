import React, { ChangeEvent, FC } from 'react';
import {
  Button, Checkbox, Input, PropsButton, SvgIcon, Text,
} from '@workstream/shared';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  avail: string,
  icon: string,
  name: string,
  checked: boolean,
  error?: boolean,
  onChangeCheckbox: (e: ChangeEvent<HTMLInputElement>, value: boolean) => void,
  value?: string,
  onChangeInput: (event: ChangeEvent<HTMLInputElement>) => void,
  onClickButton?: (event: React.MouseEvent) => void,
  buttonTextToken?: string,
  buttonColor?: PropsButton['color'],
  buttonDisabled?: boolean,
  buttonDisabledText?: string,
  inputclass?: string;
  iconclass?: string;
  margin?: string;
  availStyle?: string;
};

const LiquidityInput: FC<Props> = ({
  className,
  avail,
  inputclass,
  iconclass,
  icon,
  name,
  checked,
  error,
  onChangeCheckbox,
  value,
  onChangeInput,
  onClickButton,
  buttonTextToken = '',
  buttonColor = 'primary-dark',
  buttonDisabled,
  buttonDisabledText,
  margin,
  availStyle,
}) => (
  <div className={cx(styles.wrapper, className, margin)}>
    <div className={styles.inner}>
      <SvgIcon icon={icon} className={cx(styles.icon, iconclass)} />
      <div className={styles.inputAvailSelectAll}>
        <Input
          name={name}
          placeholder="0.00"
          className={cx(styles.input, error && styles.hasError, inputclass)}
          value={value}
          onChange={onChangeInput}
          type="number"
          onKeyPress={(event) => {
            if (['e', 'E', '-', '+'].includes(event.key)) {
              event.preventDefault();
            }
          }}
        />
        <div className={styles.availSelectAll}>
          <Text color="secondary" size="tinier" className={cx(styles.textAvail, availStyle)}>{`Avail: ${avail}`}</Text>
          <Checkbox
            name={`checkbox-${name}`}
            label="MAX"
            className={cx(styles.checkbox)}
            checked={checked}
            onChange={onChangeCheckbox}
          />
        </div>
      </div>
    </div>
    {onClickButton && (
      <Button
        color={buttonColor}
        size="sm"
        className={styles.button}
        onClick={onClickButton}
        disabled={buttonDisabled}
      >
        {buttonDisabledText || `Approve ${buttonTextToken}`}
      </Button>
    )}
  </div>
);

export default LiquidityInput;
