import React, { useMemo, } from 'react';
import cx from 'classnames';
import { Text } from "./..";
import styles from './styles.module.scss';
const Input = ({ type = 'text', name, value, disabled, onChange, onKeyPress, placeholder, classNameWrapper, className, classNameError, error, color = 'white', min, }) => {
    const renderError = useMemo(() => {
        if (typeof error === 'string' && error.length) {
            return (React.createElement(Text, { className: cx(styles.error, classNameError), size: "tiny" }, error));
        }
        return null;
    }, [error, classNameError]);
    return (React.createElement("div", { className: cx(styles.wrapper, classNameWrapper) },
        React.createElement("input", { type: type, name: name, disabled: disabled, value: value, onChange: onChange, placeholder: placeholder, min: min, className: cx(styles.input, styles[color], error && styles.inputError, className), onKeyPress: onKeyPress }),
        renderError));
};
export default Input;
