import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { lockupInfoGetBlockInfoAction } from 'store/lockupInfo/actions';
import {
  lockupInfoSelector, provideLiquiditySelector,
  useShallowSelector,
} from 'store/selectors';
import { xbeLockupGetBlockInfoAction } from 'store/xbeLockup/actions';
import { xbeStakingGetBlockInfoAction } from 'store/xbeStaking/actions';
import { formatters } from '@workstream/shared';
import BigNumber from 'bignumber.js';
import ETH from 'assets/img/coins/ethSmall.svg';
import LOCKED from 'assets/img/locked.svg';
import STAKED from 'assets/img/staked.svg';
import MARKET_CAP from 'assets/img/marketCap.svg';
import TREASURY from 'assets/img/coins/TREASURY.svg';
import TVL from 'assets/img/coins/TVL.svg';
import styles from './styles.module.scss';

function CurrencyPairs() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lockupInfoGetBlockInfoAction());
    dispatch(xbeLockupGetBlockInfoAction());
    dispatch(xbeStakingGetBlockInfoAction());
  }, [dispatch]);

  const {
    currentCirculatingSupply, totalLockedXBE, maxSupply, treasury,
    price: prices, totalStake,
  } = useShallowSelector(lockupInfoSelector.getState);

  const {
    price, totalLiquidity, stakedLp, sushiswapLp,
  } =
    useShallowSelector(provideLiquiditySelector.getState);

  const xbeUsdtValue = useMemo<string>(() => {
    const one = new BigNumber(1);
    const ethXbe = new BigNumber(prices.ETH_XBE);
    const ethUsdt = new BigNumber(prices.ETH_USDT);
    const usdtEth = one.dividedBy(ethUsdt);
    const xbeEth = one.dividedBy(ethXbe);
    const xbeUsdt = usdtEth.multipliedBy(xbeEth);

    // eslint-disable-next-line no-nested-ternary
    return !xbeUsdt.isFinite() ? '0'
      : xbeUsdt.isGreaterThan(0) ? xbeUsdt.toFixed() : '0';
  }, [prices]);

  const tvl = useMemo<string>(() => {
    const lockedXBEUsd = new BigNumber(totalLockedXBE).multipliedBy(
      xbeUsdtValue,
    );
    const lpPrice = new BigNumber(totalLiquidity)
      .multipliedBy(xbeUsdtValue)
      .div(sushiswapLp);

    const lpStaked = new BigNumber(stakedLp).multipliedBy(lpPrice);
    const tvlUsd = lockedXBEUsd.plus(lpStaked).div(1000000);

    // eslint-disable-next-line no-nested-ternary
    return !tvlUsd.isFinite()
      ? '0'
      : tvlUsd.isGreaterThan(0)
        ? tvlUsd.toFixed()
        : '0';
  }, [stakedLp, xbeUsdtValue, totalLockedXBE, totalLiquidity, sushiswapLp]);

  const treasuryUsd = useMemo<string>(() => {
    const treasuryUsdValue = new BigNumber(treasury).multipliedBy(xbeUsdtValue);
    const treasuryMillionFormat = treasuryUsdValue.div(1000000);

    // eslint-disable-next-line no-nested-ternary
    return !treasuryMillionFormat.isFinite()
      ? '0'
      : treasuryMillionFormat.isGreaterThan(0)
        ? treasuryMillionFormat.toFixed()
        : '0';
  }, [xbeUsdtValue, treasury]);

  const circulatingMarketCape = useMemo<string>(() => {
    const mSupply = new BigNumber(maxSupply);
    const treasury1 = new BigNumber(treasury);
    const lSupply = new BigNumber(totalLockedXBE);
    const XBEprice = new BigNumber(xbeUsdtValue);

    const marketCap = (mSupply.minus(treasury1)
      .minus(lSupply)).multipliedBy(XBEprice);

    // eslint-disable-next-line no-nested-ternary
    return !marketCap.isFinite() ? '0'
      : marketCap.isGreaterThan(0) ? marketCap.toFixed() : '0';
  }, [maxSupply, totalLockedXBE, xbeUsdtValue, treasury]);

  const circulatingSupply = useMemo<string>(() => {
    const mSupply1 = new BigNumber(maxSupply);
    const treasury2 = new BigNumber(treasury);
    const lSupply1 = new BigNumber(totalLockedXBE);
    const marketCap1 = mSupply1.minus(treasury2).minus(lSupply1);

    // eslint-disable-next-line no-nested-ternary
    return !marketCap1.isFinite() ? '0'
      : marketCap1.isGreaterThan(0) ? marketCap1.toFixed() : '0';
  }, [maxSupply, totalLockedXBE, treasury]);

  const lockedXbePercentage = useMemo<string>(() => {
    const totalLocked = new BigNumber(totalLockedXBE);
    const circulating = new BigNumber(circulatingSupply);
    const totalCirculating = circulating.plus(totalLocked);

    const percentage = totalLocked
      .dividedBy(totalCirculating)
      .multipliedBy(100);

    // eslint-disable-next-line no-nested-ternary
    return !percentage.isFinite()
      ? '0'
      : percentage.isGreaterThan(0)
        ? percentage.toFixed()
        : '0';
  }, [currentCirculatingSupply, totalLockedXBE]);

  const xbeStaked = useMemo<string>(() => {
    const staked = new BigNumber(totalStake);
    const locked = new BigNumber(totalLockedXBE);
    const temp = staked.minus(locked);

    // eslint-disable-next-line no-nested-ternary
    return !temp.isFinite()
      ? '0'
      : // eslint-disable-next-line no-nested-ternary
      totalLockedXBE === '0'
        ? '0'
        : temp.isGreaterThan(0)
          ? temp.toFixed()
          : '0';
  }, [totalLockedXBE, totalStake]);

  const mePercentageStake = useMemo(() => {
    if (maxSupply === '0') {
      return '0';
    }

    const value5 = new BigNumber(maxSupply).minus(treasury);
    const mePercentOfTotalStakedBN =
      new BigNumber(xbeStaked).div(value5).toNumber();

    return Number.isNaN(mePercentOfTotalStakedBN) ? '0'
      : (mePercentOfTotalStakedBN * 100).toFixed(2);
  }, [xbeStaked, maxSupply, treasury]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.values}>
        <div className={styles.content1}>
          <img
            className={styles.lockedAvatar}
            src={TVL}
            alt="lockedAvatar"
          />
          <span className={styles.value}>
            {`$${formatters.toFixed(tvl, 1)}`} M
          </span>
          <span className={styles.chip}>TVL</span>
        </div>
        <div className={styles.content1}>
          <img
            className={styles.avatar}
            src={ETH}
            alt="avatar"
          />
          <span className={styles.value}>
            {formatters.toFormatViaBN(price, 2)}
          </span>
          <span className={styles.text}>ETH/XBE</span>
        </div>
      </div>
      <div className={styles.values1}>
        <div className={styles.pairsRow}>
          <div className={styles.row}>
            <div className={styles.content}>
              <img src={LOCKED} className={styles.ContentIcon} alt="locked" />
              <span className={styles.value}>
                {formatters.toFormatViaBN(lockedXbePercentage, 0)}%
              </span>
              <div className={styles.chip}>Locked (%)</div>
            </div>
            <div className={styles.content}>
              <img src={STAKED} className={styles.ContentIcon} alt="staked" />
              <span className={styles.value}>
                {formatters.toFormatViaBN(mePercentageStake, 0)}%
              </span>
              <div className={styles.chip}>Staked (%)</div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.content}>
              <span className={styles.value}>
                {formatters.toFormatViaBN(totalLockedXBE, 0)}
              </span>
              <div className={styles.chip}>XBE Locked</div>
            </div>
            <div className={styles.content}>
              <span className={styles.value}>
                {formatters.toFormatViaBN(xbeStaked, 0)}
              </span>
              <div className={styles.chip}>XBE Staked</div>
            </div>
          </div>
        </div>
        <div className={styles.pairsRow1}>
          <div className={styles.row}>
            <div className={styles.content}>
              <img
                src={MARKET_CAP}
                className={styles.ContentIcon}
                alt="marketCape"
              />
              <span className={styles.value}>
                ${formatters.toFormatViaBN(circulatingMarketCape, 0)}
              </span>
              <div className={styles.chip}>Circulating Market Cap</div>
            </div>
            <div className={styles.content}>
              <img
                src={TREASURY}
                className={styles.ContentIcon}
                alt="dMarketCape"
              />
              <span className={styles.value}>
                ${formatters.toFixed(treasuryUsd, 1)}M
              </span>
              <div className={styles.chip}>XBE Treasury Value</div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.content}>
              <span className={styles.value}>
                {formatters.toFormatViaBN(circulatingSupply, 0)}
              </span>
              <div className={styles.chip}>Circulating XBE supply</div>
              <small className={styles.small}>*excl locked & treasury</small>
            </div>
            <div className={styles.content}>
              <span className={styles.value}>
                {formatters.toFormatViaBN(treasury, 0)}
              </span>
              <div className={styles.chip}>XBE in Treasury</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CurrencyPairs;
