/* eslint-disable @typescript-eslint/quotes */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from 'appConstants';
import { Easy } from 'pages';
import { Header } from 'containers';
import { useLocation } from 'react-router';
// import Easy2 from 'pages/Easy2';
import Footer from 'containers/Footer';

enum Tabs {
  easyXbe = 0,
  easyHive = 1,
}

const Routes = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = React.useState(Tabs.easyXbe);

  const setEasy2Active = () => {
    setActiveTab(Tabs.easyHive);
  };

  const setEasyActive = () => {
    setActiveTab(Tabs.easyXbe);
  };

  return (
    <>
      <Header setEasy2Active={setEasy2Active} setEasyActive={setEasyActive} activeTab={activeTab} />
      {location.pathname !== routes.lockup.root && (
        <div className="container">
          {/* <Statistics /> */}
        </div>
      )}

      <Switch>
        <Route path="/" component={Easy} exact />
      </Switch>
      <Footer />
    </>
  );
};

export default Routes;
