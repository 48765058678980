import { MeActionTypes } from 'store/me/actionTypes';
import { MeState } from 'store/me/types';
import { SetStatusPayload } from 'store/types';
import { BalancesToken } from './constants';

export const meSetStateAction = (payload: Partial<MeState>) => ({
  type: MeActionTypes.SET_STATE,
  payload,
});

export const meSetStatusAction = (payload: SetStatusPayload<MeActionTypes>) => ({
  type: MeActionTypes.SET_STATUS,
  payload,
});

export const meGetBalancesAction =
  (payload: BalancesToken) => ({
    type: MeActionTypes.GET_BALANCES,
    payload,
  });

export const meGetBalanceEthAction = () => ({
  type: MeActionTypes.GET_BALANCE_ETH,
});

export const meBalancesSetStatusAction = (
  payload: Partial<MeState['uiBalances']>,
) => ({
  type: MeActionTypes.SET_BALANCES_STATUS,
  payload,
});

export const meSetBalanceAction = (payload: Partial<MeState['balances']>) => ({
  type: MeActionTypes.SET_BALANCE,
  payload,
});

export const meGetIsLockedXbeAction = () => ({
  type: MeActionTypes.GET_IS_LOCKED_XBE,
});

export const meGetBoostLevelAction = () => ({
  type: MeActionTypes.GET_BOOST_LEVEL,
});

export const meGetCurveApysAction = () => ({
  type: MeActionTypes.GET_CURVE_APYS,
});

export const meSetIsSignedAction = (payload: { userSignedData: string }) => ({
  type: MeActionTypes.SET_SIGNED,
  payload,
});

export const meGetIsSignedAction = () => ({
  type: MeActionTypes.GET_IS_SIGNED,
});
