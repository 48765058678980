/* eslint-disable no-console */
import { put, takeEvery } from 'redux-saga/effects';
// import axios from 'axios';
import { LocationActionTypes } from 'store/location/actionTypes';
import {
  getLocationAction,
  locationSetStatusAction,
  locationSetStateAction,
} from 'store/location/actions';
import {
  RequestStatus,
  setNotification,
} from '@workstream/shared';
// import axios from 'axios';

function* locationGetLocationSaga({
  type,
}: ReturnType<typeof getLocationAction>) {
  try {
    yield put(locationSetStatusAction({ type, status: RequestStatus.REQUEST }));

    // @ts-ignore
    let result;
    yield fetch('https://lucky-moon-d435.api1836.workers.dev/')
      .then((response) => response.json())
      .then((data) => {
        result = data;
      })
      .catch((err) => console.log(err));

    yield put(
      locationSetStateAction({
        // @ts-ignore
        country: result.countryCode,
      }),
    );

    yield put(locationSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location getLocation');
    yield put(locationSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getLocationSaga() {
  yield takeEvery(
    LocationActionTypes.GET_LOCATION,
    locationGetLocationSaga,
  );
}
