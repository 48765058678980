import React, { useEffect, useMemo } from 'react';
import XBE_ETH from 'assets/img/coins/XBE-ETH.svg';
import APR from 'assets/img/APR.svg';
import DEX from 'assets/img/DEX.svg';
import VECTOR from 'assets/img/Vector.svg';
import {
  claimSelector,
  lockupInfoSelector,
  provideLiquiditySelector, useShallowSelector,
} from 'store/selectors';
import { formatters } from '@workstream/shared';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { ClaimTokens } from 'store/claim/constants';
import {
  claimGetStakeAction,
} from 'store/claim/actions';
import { provideLiquidityGetTotalLiquidityAction } from 'store/provideLiquidity/actions';
import { lockupInfoGetBlockInfoAction } from 'store/lockupInfo/actions';
import styles from './styles.module.scss';

function BuyNow() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(provideLiquidityGetTotalLiquidityAction());
    dispatch(lockupInfoGetBlockInfoAction());
    dispatch(claimGetStakeAction(ClaimTokens.SUSHI));
  }, [dispatch]);

  const {
    totalLiquidity,
    // sushiswapLp: totalSlpTokens,
    // stakedLp: totalStackedLpTokens,
  } = useShallowSelector(
    provideLiquiditySelector.getState,
  );
  const { price: prices } = useShallowSelector(lockupInfoSelector.getState);

  const { apr } = useShallowSelector(claimSelector.getProp(ClaimTokens.SUSHI));

  const xbeUsdValue = useMemo<string>(() => {
    const one = new BigNumber(1);
    const ethXbe = new BigNumber(prices.ETH_XBE);
    const ethUsdt = new BigNumber(prices.ETH_USDT);
    const usdtEth = one.dividedBy(ethUsdt);
    const xbeEth = one.dividedBy(ethXbe);
    const xbeUsdt = usdtEth.multipliedBy(xbeEth);

    // eslint-disable-next-line no-nested-ternary
    return !xbeUsdt.isFinite() ? '0'
      : xbeUsdt.isGreaterThan(0) ? xbeUsdt.toFixed() : '0';
  }, [prices]);

  // const apr = useMemo<string>(() => {
  //   const annualRewards = '7488';

  //   const annualRewardsInUsd = new BigNumber(annualRewards).multipliedBy(
  //     xbeUsdValue,
  //   );

  //   const ethXbeUsdValue = new BigNumber(totalLiquidity).multipliedBy(
  //     xbeUsdValue,
  //   );

  //   const tokenUsdValue = ethXbeUsdValue.div(totalSlpTokens);
  //   const rewardsPerSLp = annualRewardsInUsd.div(totalStackedLpTokens);
  //   const aprValue = rewardsPerSLp
  //     .div(tokenUsdValue)
  //     .multipliedBy(100)
  //     .multipliedBy(2);

  //   // eslint-disable-next-line no-nested-ternary
  //   return !aprValue.isFinite()
  //     ? '0'
  //     : aprValue.isGreaterThan(0)
  //       ? aprValue.toFixed()
  //       : '0';
  // }, [totalLiquidity, xbeUsdValue, totalSlpTokens, totalStackedLpTokens]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.values}>
        <div
          className={styles.hue}
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '10px',
          }}
        >
          <div className={styles.flexContainer}>
            <img className={styles.avatar} src={XBE_ETH} alt="xbeEth" />
            <div className={styles.textDiv}>
              <div className={styles.value2}>XBE/ETH</div>
              <div className={styles.text1}>Sushiswap</div>
            </div>
          </div>
        </div>
        <button className={styles.buyButton} type="button">
          {' '}
          <a
            href="https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x5de7cc4bcbca31c473f6d2f27825cfb09cc0bb16"
            target="_blank"
            rel="noreferrer"
          >
            Buy XBE
          </a>
        </button>
      </div>
      <div className={styles.values1}>
        <div className={styles.content}>
          {/* <div className={styles.resumeBlock}>XBE SushiSwap LP
            <b>Rewards Resume Mon 6 Dec ‘21</b>
          </div> */}
          <div className={styles.value}>
            {apr ? `${formatters.toFixed(apr, 0)}%` : '0%'}
          </div>
          <div className={styles.text}>Sushiswap LP APR</div>
          <a
            className={styles.reportsText}
            href="https://xbf-rewards.xbe.finance/"
            target="_blank"
            rel="noreferrer"
          >
            (View XBF Reports)
          </a>
          <button type="button" className={styles.outlinedButton}>
            <a href="https://vfat.tools/xbe/" target="_blank" rel="noreferrer">
              View APR on Vfat.tools
            </a>
            <img className={styles.avatar2} src={APR} alt="apr" />
          </button>
        </div>
        <div className={styles.lockup}>
          <img className={styles.lpimg} src={VECTOR} alt="vector" />
          <div className={styles.lp}>NO LP LOCKUP</div>
        </div>
        <div className={styles.content}>
          <div className={styles.value}>
            $
            {formatters.toFormatViaBN(
              String(new BigNumber(totalLiquidity).multipliedBy(xbeUsdValue)),
              0,
            )}
          </div>
          <div className={styles.text}>SushiSwap XBE/ETH Liquidity</div>
          <button className={styles.outlinedButton2} type="button">
            <a
              href="https://www.dextools.io/app/ether/pair-explorer/0x1d46ac355f9f338d9edea6c072120abe90d67bee"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              View Pool on DexTools{' '}
            </a>
            <img className={styles.avatar2} src={DEX} alt="dex" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default BuyNow;
