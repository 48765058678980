/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { put, takeEvery, call } from 'redux-saga/effects';
import { MeActionTypes } from 'store/me/actionTypes';
import {
  getContract,
  RequestStatus,
  setNotification,
  web3,
} from '@workstream/shared';
import {
  meSetStateAction,
  meGetIsSignedAction,
  meSetStatusAction,
} from 'store/me/actions';
import { selectAddress } from 'store/utils';
import firebase from 'utils/firebase';
import {
  getDatabase, ref, child, get,
} from 'firebase/database';

// @ts-ignore
const db = getDatabase(firebase);

function* meGetIsSignedSaga({ type }: ReturnType<typeof meGetIsSignedAction>) {
  try {
    yield put(meSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    let isSignedValue = false;
    const dbRef = ref(getDatabase());

    yield get(child(dbRef, `users/${address}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          isSignedValue = true;
        }
      })
      .catch((error) => {
        console.error(error);
      });

    yield put(
      meSetStateAction({
        isSigned: isSignedValue,
      }),
    );
    yield put(meSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (err) {
    setNotification({ type: 'error', message: err.message });
    console.log('error', err.message);
    console.log('error location meGetIsSignedSaga');
    yield put(meSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getIsSignedSaga() {
  yield takeEvery(MeActionTypes.GET_IS_SIGNED, meGetIsSignedSaga);
}
