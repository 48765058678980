/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/quotes */
import React, { useEffect, useMemo, useState } from 'react';
import {
  formatters,
  H1, H4, MetamaskStatus, Text, web3,
} from '@workstream/shared';
import cx from 'classnames';
import CurrencyPairs from 'containers/CurrencyPairs';
import BuyNow from 'containers/BuyNow';
import ProtocolRoad from 'containers/ProtocolRoadMap';
import BigNumber from 'bignumber.js';
import XBE from 'assets/img/coins/XBE.svg';
import {
  boostSelector,
  lockupInfoSelector,
  useShallowSelector,
  web3Selector,
  locationSelector,
} from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { lockupInfoGetBlockInfoAction } from 'store/lockupInfo/actions';
import { xbeStakingGetBlockInfoAction } from 'store/xbeStaking/actions';
import { getLocationAction } from 'store/location/actions';
import { provideLiquidityGetTotalLiquidityAction } from 'store/provideLiquidity/actions';
import { boostGetBlockDepositInfoAction } from 'store/boost/actions';
import { RequirementWalletProvider, Blocked } from 'containers';
import Footer from 'containers/Footer';
import Restricted from 'containers/Restricted';
import styles from './styles.module.scss';
import Earnings from '../../containers/Earnings';
import Logos from '../../containers/Logos';

const TWO_YEARS = 24 * 2_629_746_000;

const Easy = () => {
  const dispatch = useDispatch();
  const metamaskStatus = useSelector(web3Selector.getProp('metamaskStatus'));
  const { price: prices } = useShallowSelector(lockupInfoSelector.getState);
  const country = useSelector(locationSelector.getProp('country'));

  useEffect(() => {
    dispatch(lockupInfoGetBlockInfoAction());
    dispatch(xbeStakingGetBlockInfoAction());
    dispatch(provideLiquidityGetTotalLiquidityAction());
  }, [dispatch]);

  const isInAmerica = country.toLowerCase() === 'us';

  const {
    myXBELocked, hasMaxBoostLevel,
  } = useSelector(boostSelector.getState);

  const fixedMyXbeLocked = web3.utils.fromWei(myXBELocked);

  useEffect(() => {
    if (metamaskStatus === MetamaskStatus.ADDRESS_SELECTED) {
      dispatch(boostGetBlockDepositInfoAction());
    }
  }, [dispatch, metamaskStatus]);

  const xbeUsdtValue = useMemo<string>(() => {
    const one = new BigNumber(1);
    const ethXbe = new BigNumber(prices.ETH_XBE);
    const ethUsdt = new BigNumber(prices.ETH_USDT);
    const usdtEth = one.dividedBy(ethUsdt);
    const xbeEth = one.dividedBy(ethXbe);
    const xbeUsdt = usdtEth.multipliedBy(xbeEth);

    // eslint-disable-next-line no-nested-ternary
    return !xbeUsdt.isFinite() ? '0' : xbeUsdt.isGreaterThan(0) ? xbeUsdt.toFixed() : '0';
  }, [prices]);

  return (
    <div className={cx('container', styles.easycontainer)}>
      {isInAmerica ? (
        <Restricted />
      ) : (
        <>
          <div className={styles.headerContainer}>
            <span className={styles.block}>
              <div className={styles.info}>
                <img src={XBE} alt="logo" className={styles.logo} />
                <span className={styles.textContainer}>
                  <Text
                    tag="span"
                    size="giant"
                    className={styles.infoText}
                    align="center"
                  >
                    ${formatters.toFormatViaBN(xbeUsdtValue, 0)}
                  </Text>
                  <span className={styles.label}>USD/XBE</span>
                </span>
              </div>
            </span>
            <div className={cx('container', styles.container)}>
              <H1 className={styles.title}>
                START EARNING IN DEFI THE EASY WAY
              </H1>
              <Text className={styles.description} color="secondary">
                {`We’ve made the Easy XBE router smart contract to make it
               easy for new users to start earning in DeFi by staking their
              liquidity in the XBE protocol - even if they only have ETH
              in their wallets!  Just deposit your ETH to the router contract
              in the UI below, and it will balance your liquidity with both
              assets, add them to the pool, and stake the LP tokens for you
              all in one!  The easiest way to get started with XBE!`}
              </Text>
              {metamaskStatus === MetamaskStatus.ADDRESS_SELECTED &&
              hasMaxBoostLevel ? (
                <div className={styles.bonusSection}>
                  <H4 className={styles.bonusText}>
                    XBE Bonus Lockup Wallet Detected
                  </H4>
                  <div className={styles.xbeIcon}>
                    <img src={XBE} alt="bonusXBE" />
                    <span className={styles.badge}>
                      {formatters.toFixed(fixedMyXbeLocked, 0)}
                    </span>
                  </div>
                </div>
                ) : null}
            </div>
          </div>
          <CurrencyPairs />
          <Logos />
          <BuyNow />
          <RequirementWalletProvider>
            <Earnings />
          </RequirementWalletProvider>
          <ProtocolRoad />
        </>
      )}
    </div>
  );
};

export default Easy;
