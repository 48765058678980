/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import ETH from 'assets/img/coins/eth.svg';
import PROTOCOL from 'assets/img/coins/PROTOCOL.png';
import POLYGON from 'assets/img/coins/polygon.svg';
import FANTOM from 'assets/img/coins/fantom.svg';
import BINANCE from 'assets/img/coins/binance.svg';
import styles from './styles.module.scss';

function ProtocolRoad() {
  return (
    <span className={styles.wrapper}>
      <div className={styles.middlebox}>
        <span className={styles.sectionHeader}>Protocol {' '}
          <span
            className={styles.roadmaplink}
            onClick={() => window.open('https://xbe.finance/blog/this-is-xbe/', '_blank')}
          >Roadmap
          </span>
        </span>
        <span
          className={styles.phasesImageContainer}
        >
          <div className={styles.phases}>
            <span className={styles.stakephase}>
              <span>Phase 1 - STAKING
                <a
                  className={styles.readText}
                  href="https://xbe.finance/blog/1-8-guide-staking-and-lockups/"
                  target="_blank"
                  rel="noopener noreferrer"
                > (read blog)
                </a>
              </span>
              <span>LIVE</span>
            </span>
            <span className={styles.hivephase}>
              <span className={styles.hiveText}>Phase 2 - HIVE
                <a
                  className={styles.readText}
                  href="https://xbe.finance/blog/what-is-xbe-hive"
                  target="_blank"
                  rel="noopener noreferrer"
                > (read blog)
                </a>
              </span>
              <span className={styles.hiveText}>NEXT</span>
            </span>
            <span className={styles.phase} style={{ backgroundColor: '#E0EBF3' }}>
              <span>Phase 3 - NFT FACTORY</span>
              <span>COMING SOON</span>
            </span>
            <span className={styles.phase} style={{ backgroundColor: '#E0EBF3' }}>
              <span>Phase 4 - VAULT</span>
              <span>COMING SOON</span>
            </span>
          </div>
          <div className={styles.graphContainer}>
            <img className={styles.graph} src={PROTOCOL} />
          </div>
        </span>
        <span className={styles.pairsContaier}>
          <div className={styles.content}>
            <img className={styles.avatar} src={ETH} />
            <span className={styles.value} style={{ fontSize: '20px' }}>
              ETHEREUM
            </span>
            <span className={styles.live}>LIVE</span>
          </div>
          <div className={styles.content}>
            <img className={styles.avatar} src={FANTOM} />
            <span className={styles.value} style={{ fontSize: '20px' }}>
              FANTOM
            </span>
            <div className={styles.chip}>COMING SOON</div>
          </div>
          <div className={styles.content}>
            <img className={styles.avatar} src={POLYGON} />
            <span className={styles.value} style={{ fontSize: '20px' }}>
              POLYGON
            </span>
            <div className={styles.chip}>COMING SOON</div>
          </div>
          <div className={styles.content}>
            <img className={styles.avatar} src={BINANCE} />
            <span className={styles.value} style={{ fontSize: '20px' }}>
              BINANCE
            </span>
            <div className={styles.chip}>COMING SOON</div>
          </div>
        </span>
      </div>
    </span>
  );
}

export default ProtocolRoad;
