export const XBE_CONTRACT_ADDRESS =
  process.env.REACT_APP_XBE_CONTRACT_ADDRESS as string;

export const XBE_ROUTER_CONTRACT_ADDRESS =
  process.env.REACT_APP_XBE_ROUTER_CONTRACT_ADDRESS as string;

export const WETH_CONTRACT_ADDRESS =
  process.env.REACT_APP_WETH_CONTRACT_ADDRESS as string;

export const HIVE_VAULT_CONTRACT_ADDRESS =
  process.env.REACT_APP_HIVE_VAULT_CONTRACT_ADDRESS as string;

export const XBE_INFLATION_CONTRACT_ADDRESS =
  process.env.REACT_APP_XBE_INFLATION_CONTRACT_ADDRESS as string;

export const VE_XBE_CONTRACT_ADDRESS =
  process.env.REACT_APP_VE_XBE_CONTRACT_ADDRESS as string;

export const TREASURY_CONTRACT_ADDRESS =
  process.env.REACT_APP_TREASURY_CONTRACT_ADDRESS as string;

export const VOTING_CONTRACT_ADDRESS =
  process.env.REACT_APP_VOTING_CONTRACT_ADDRESS as string;

export const VOTING_STAKING_REWARDS_CONTRACT_ADDRESS =
  process.env.REACT_APP_VOTING_STAKING_REWARDS_CONTRACT_ADDRESS as string;

export const BONUS_CAMPAIGN_CONTRACT_ADDRESS =
  process.env.REACT_APP_BONUS_CAMPAIGN_CONTRACT_ADDRESS as string;

export const ETH_XBE_PAIR_CONTRACT_ADDRESS =
  process.env.REACT_APP_ETH_XBE_PAIR_CONTRACT_ADDRESS as string;

export const SUSHISWAP_ROUTER_CONTRACT_ADDRESS =
  process.env.REACT_APP_SUSHISWAP_ROUTER_CONTRACT_ADDRESS as string;

export const REFERRAL_PROGRAM_CONTRACT_ADDRESS =
  process.env.REACT_APP_REFERRAL_PROGRAM_CONTRACT_ADDRESS as string;

export const SUSHI_VAULT_CONTRACT_ADDRESS =
  process.env.REACT_APP_SUSHI_VAULT_CONTRACT_ADDRESS as string;

export const CVX_CONTRACT_ADDRESS =
  process.env.REACT_APP_CVX_CONTRACT_ADDRESS as string;

export const CRV_CONTRACT_ADDRESS =
  process.env.REACT_APP_CRV_CONTRACT_ADDRESS as string;

export const CVX_CRV_CONTRACT_ADDRESS =
  process.env.REACT_APP_CVX_CRV_CONTRACT_ADDRESS as string;

export const CVX_VAULT_CONTRACT_ADDRESS =
  process.env.REACT_APP_CVX_VAULT_CONTRACT_ADDRESS as string;

export const CVX_CRV_VAULT_CONTRACT_ADDRESS =
  process.env.REACT_APP_CVX_CRV_VAULT_CONTRACT_ADDRESS as string;

export const CVX_CRV_STRATEGY_CONTRACT_ADDRESS =
  process.env.REACT_APP_CVX_CRV_STRATEGY_CONTRACT_ADDRESS as string;

export const IRON_BANK_CONTRACT_ADDRESS =
  process.env.REACT_APP_IRON_BANK_CONTRACT_ADDRESS as string;
export const USDN_CONTRACT_ADDRESS =
  process.env.REACT_APP_USDN_CONTRACT_ADDRESS as string;
export const ST_ETH_CONTRACT_ADDRESS =
  process.env.REACT_APP_ST_ETH_CONTRACT_ADDRESS as string;
export const EURS_CONTRACT_ADDRESS =
  process.env.REACT_APP_EURS_CONTRACT_ADDRESS as string;

export const VAULT_IRON_BANK_CONTRACT_ADDRESS =
  process.env.REACT_APP_VAULT_IRON_BANK_CONTRACT_ADDRESS as string;
export const VAULT_USDN_CONTRACT_ADDRESS =
  process.env.REACT_APP_VAULT_USDN_CONTRACT_ADDRESS as string;
export const VAULT_ST_ETH_CONTRACT_ADDRESS =
  process.env.REACT_APP_VAULT_ST_ETH_CONTRACT_ADDRESS as string;
export const VAULT_EURS_CONTRACT_ADDRESS =
  process.env.REACT_APP_VAULT_EURS_CONTRACT_ADDRESS as string;

export const VAULT_EURT_CONTRACT_ADDRESS = process.env
  .REACT_APP_VAULT_EURT_CONTRACT_ADDRESS as string;
