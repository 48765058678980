/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/quotes */

/* eslint-disable react/button-has-type */
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import detectEthereumProvider from '@metamask/detect-provider';
import {
  meSelector,
  provideLiquiditySelector,
  useShallowSelector,
  web3Selector,
  locationSelector,
} from 'store/selectors';
import { BlockWithList, LiquidityInput } from "components";
import { DECIMALS } from "appConstants";
import { BlockWithListProps } from "components/BlockWithList";
import { RequirementWalletProvider } from "containers";
import {
  provideLiquidityAddLiquidityAction,
  provideLiquidityAddLiquidityOnlyEthAction,
  provideLiquidityApproveTokenAction,
  provideLiquidityGetPriceAction,
  provideLiquidityGetTokenAllowanceAction,
  provideLiquidityGetTotalLiquidityAction,
} from "store/provideLiquidity/actions";
import { getLocationAction } from 'store/location/actions';
import { meGetIsSignedAction, meSetIsSignedAction } from 'store/me/actions';
import { ProvideLiquidityActionTypes } from "store/provideLiquidity/actionTypes";
import {
  Button,
  CoinEth,
  formatters,
  MetamaskStatus,
  PlatformHeaderBlock,
  Preloader,
  RequestStatus,
  Text,
  setNotification,
} from '@workstream/shared';
import XBE from 'assets/img/coins/XBE.svg';
import TermsAndConditions from 'containers/TermsAndConditions';
import cx from "classnames";
import BigNumber from "bignumber.js";
import { fixDecimals } from "utils";
import styles from "./styles.module.scss";

const MS_TO_DETECT_METAMASK = 10;

type Values = {
  amountEth: string;
  amountToken: string;
  isCheckedMaxEth: boolean;
  isCheckedMaxToken: boolean;
};

const initialValues: Values = {
  amountEth: "0",
  amountToken: "0",
  isCheckedMaxEth: false,
  isCheckedMaxToken: false,
};

enum ContractActions {
  depositAndStake = 0,
  approve = 1,
  deposit = 2,
}

function checkNeedApprove(allowance: string, value: string): boolean {
  const allowanceBN = new BigNumber(allowance);
  const valueBN = new BigNumber(value);

  if (!valueBN.isGreaterThan(0)) {
    return true;
  }

  return allowanceBN.isLessThan(valueBN);
}

Yup.addMethod(
  Yup.string,
  "amountEth",
  function (errorMessage: string, ethBalance: string) {
    return this.test("test-amount-eth", errorMessage, function (value) {
      const { path, createError } = this;
      if (value) {
        const valueBN = new BigNumber(value);

        const conditions: boolean[] = [
          valueBN.isGreaterThan(0),
          valueBN.decimalPlaces() <= DECIMALS,
          valueBN.isLessThanOrEqualTo(ethBalance),
        ];

        if (conditions.includes(false)) {
          return createError({
            path,
            message: errorMessage,
          });
        }
        return true;
      }

      return false;
    });
  },
);

Yup.addMethod(
  Yup.string,
  "amountToken",
  function (
    errorMessage: string,
    tokenBalance: string,
    allowanceToken: string,
  ) {
    return this.test("test-amount-token", errorMessage, function (value) {
      const { path, createError } = this;
      if (value) {
        const valueBN = new BigNumber(value);

        const conditions: boolean[] = [
          valueBN.isGreaterThan(0),
          valueBN.decimalPlaces() <= DECIMALS,
          valueBN.isLessThanOrEqualTo(tokenBalance),
          valueBN.isLessThanOrEqualTo(allowanceToken),
        ];

        if (conditions.includes(false)) {
          return createError({
            path,
            message: errorMessage,
          });
        }
        return true;
      }

      return false;
    });
  },
);

function Earnings() {
  const dispatch = useDispatch();
  const { price, priceReverse, allowanceToken } = useShallowSelector(
    provideLiquiditySelector.getState,
  );

  const allowanceReqStatus = useShallowSelector(
    provideLiquiditySelector.getStatus(
      ProvideLiquidityActionTypes.GET_ALLOWANCE_TOKEN,
    ),
  );
  const approveReqStatus = useShallowSelector(
    provideLiquiditySelector.getStatus(
      ProvideLiquidityActionTypes.APPROVE_TOKEN,
    ),
  );
  const depositReqStatus = useShallowSelector(
    provideLiquiditySelector.getStatus(
      ProvideLiquidityActionTypes.ADD_LIQUIDITY,
    ),
  );
  const depositOnlyEthReqStatus = useShallowSelector(
    provideLiquiditySelector.getStatus(
      ProvideLiquidityActionTypes.ADD_LIQUIDITY_ONLY_ETH,
    ),
  );
  const ethBalance = useShallowSelector(meSelector.getProp('eth'));
  const { xbe: tokenBalance } = useShallowSelector(
    meSelector.getProp('balances'),
  );
  const metamaskStatus = useShallowSelector(
    web3Selector.getProp('metamaskStatus'),
  );

  const metamaskAddress = useShallowSelector(web3Selector.getProp('address'));

  const ethBalanceFixed = useMemo(
    () => new BigNumber(ethBalance).toFixed(4),
    [ethBalance],
  );
  const tokenBalanceFixed = useMemo(
    () => new BigNumber(tokenBalance).toFixed(4),
    [tokenBalance],
  );

  const country = useSelector(locationSelector.getProp('country'));
  const isSigned = useShallowSelector(meSelector.getProp('isSigned'));

  // the following assignment is temporary, need to remove once we have
  // the go ahead to deploy metamask verification to the live site
  // isSigned = true;

  const dialog = React.useRef(null);
  const dialogContent = React.useRef(null);
  const [contractAction, setContractAction] = useState(
    ContractActions.depositAndStake,
  );
  const stateRef = React.useRef();

  React.useEffect(() => {
    // @ts-ignore
    stateRef.current = contractAction;
  }, [contractAction]);

  const isInAmerica = country.toLowerCase() === 'us';

  const handleOpenDialog = () => {
    if (dialog.current) {
      // @ts-ignore
      dialog.current.style.display = 'block';
    }
  };

  const handleCloseDialog = () => {
    if (dialog.current) {
      // @ts-ignore
      dialog.current.style.display = 'none';
    }
  };

  const handleEscape = (e: KeyboardEvent) => {
    if (
      e.keyCode === 27 ||
      // @ts-ignore
      (dialog.current && !dialog.current.contains(e.target))
    ) {
      handleCloseDialog();
    }
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (
      dialog.current &&
      dialogContent.current &&
      // @ts-ignore
      dialog.current.contains(e.target) &&
      // @ts-ignore
      !dialogContent.current.contains(e.target)
    ) {
      handleCloseDialog();
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handleEscape);
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('keydown', handleEscape);
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    dispatch(provideLiquidityGetPriceAction());
    dispatch(provideLiquidityGetTotalLiquidityAction());
    dispatch(provideLiquidityGetTokenAllowanceAction());
    dispatch(meGetIsSignedAction());
  }, [dispatch]);

  const {
    values,
    errors,
    touched,
    isValid,
    resetForm,
    setTouched,
    setValues,
    handleSubmit,
    setErrors,
  } = useFormik<Values>({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      amountEth: Yup.string()
        // @ts-ignore
        .amountEth('Custom val fail', ethBalance)
        .required(),
      amountToken: Yup.string()
        // @ts-ignore
        .amountToken('Custom token val fail', tokenBalance, allowanceToken)
        .required(),
    }),
    onSubmit: ({ amountEth, amountToken }) => {
      dispatch(
        provideLiquidityAddLiquidityAction({
          amountToken,
          amountEth,
        }),
      );
    },
  });

  /**
   * Reset formik errors after success approve
   */
  useEffect(() => {
    if (allowanceReqStatus === RequestStatus.SUCCESS) {
      setErrors({});
    }
  }, [allowanceReqStatus, setErrors]);

  const optionsList = useMemo<BlockWithListProps['list']>(
    () => [
      {
        label: 'Price',
        value: formatters.toFormatViaBN(price, 5),
      },
    ],
    [price],
  );

  const handleSetTouchedInputs = useCallback(() => {
    setTouched({
      ...touched,
      amountEth: true,
      amountToken: true,
    });
  }, [setTouched, touched]);

  const handleSelectMaxETH = useCallback(
    (checked: boolean) => {
      if (checked) {
        const amountToken = new BigNumber(ethBalance).div(price);

        setValues({
          ...values,
          amountEth: ethBalance,
          amountToken: fixDecimals(amountToken),
          isCheckedMaxEth: checked,
          isCheckedMaxToken: false,
        }).then(() => handleSetTouchedInputs());
      } else {
        resetForm();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values, touched, ethBalance, tokenBalance, price],
  );

  const handleSelectMaxToken = useCallback(
    (checked: boolean) => {
      if (checked) {
        const amountEth = new BigNumber(tokenBalance).div(priceReverse);

        setValues({
          ...values,
          amountEth: fixDecimals(amountEth),
          amountToken: tokenBalance,
          isCheckedMaxToken: checked,
          isCheckedMaxEth: false,
        }).then(() => handleSetTouchedInputs());
      } else {
        resetForm();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values, touched, ethBalance, tokenBalance, priceReverse],
  );

  const handleChangeETH = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const valueBN = new BigNumber(value);

      if (valueBN.decimalPlaces() > DECIMALS) return;

      const amountTokenBN = valueBN.multipliedBy(priceReverse);

      setValues({
        ...values,
        amountEth: value,
        amountToken: fixDecimals(amountTokenBN),
        isCheckedMaxEth: false,
        isCheckedMaxToken: false,
      }).then(() => handleSetTouchedInputs());
    },
    [handleSetTouchedInputs, setValues, values, priceReverse],
  );

  const handleChangeToken = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const valueBN = new BigNumber(value);

      if (valueBN.decimalPlaces() > DECIMALS) return;

      const amountEthBN = valueBN.div(priceReverse);

      setValues({
        ...values,
        amountEth: fixDecimals(amountEthBN),
        amountToken: value,
        isCheckedMaxToken: false,
        isCheckedMaxEth: false,
      }).then(() => handleSetTouchedInputs());
    },
    [handleSetTouchedInputs, priceReverse, setValues, values],
  );

  const isNeedApproveToken = useMemo(
    () => checkNeedApprove(allowanceToken, values.amountToken),
    [allowanceToken, values.amountToken],
  );

  const handleApproveToken = useCallback(() => {
    dispatch(provideLiquidityApproveTokenAction(values.amountToken));
  }, [dispatch, values.amountToken]);

  const isDepositButtonDisabled = useMemo(
    () => [
      !isValid,
      depositOnlyEthReqStatus === RequestStatus.REQUEST,
      depositReqStatus === RequestStatus.REQUEST,
    ].includes(true),
    [isValid, depositOnlyEthReqStatus, depositReqStatus],
  );

  // Only Eth
  const [isCheckedMaxOnlyEth, setIsCheckedMaxOnlyEth] = useState(false);
  const [valueOnlyEth, setValueOnlyEth] = useState('0');

  const handleInputOnlyEth = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const valueBN = new BigNumber(value);

    if (valueBN.decimalPlaces() > DECIMALS) {
      setValueOnlyEth(valueBN.toFixed(DECIMALS, 1));
    } else {
      setValueOnlyEth(value);
    }
    setIsCheckedMaxOnlyEth(false);
  };

  useEffect(() => {
    if (isCheckedMaxOnlyEth) {
      setValueOnlyEth(new BigNumber(ethBalance).toFixed(DECIMALS, 1));
    }
  }, [isCheckedMaxOnlyEth, ethBalance]);

  const isErrorInputOnlyEth = useMemo(() => {
    const valueBN = new BigNumber(valueOnlyEth);
    return [
      valueBN.isGreaterThan(ethBalance),
      valueBN.decimalPlaces() > DECIMALS,
    ].includes(true);
  }, [valueOnlyEth, ethBalance]);

  const isFundOnlyEthButtonDisabled = useMemo(
    () => [
      valueOnlyEth === '' || valueOnlyEth === '0',
      isErrorInputOnlyEth,
      depositOnlyEthReqStatus === RequestStatus.REQUEST,
      depositReqStatus === RequestStatus.REQUEST,
    ].includes(true),
    [
      isErrorInputOnlyEth,
      depositOnlyEthReqStatus,
      depositReqStatus,
      valueOnlyEth,
    ],
  );

  const onFundOnlyEthClick = useCallback(() => {
    return false;
    dispatch(
      provideLiquidityAddLiquidityOnlyEthAction(
        new BigNumber(valueOnlyEth).toFixed(DECIMALS, 1),
      ),
    );
  }, [dispatch, valueOnlyEth]);

  const msgParams = [
    {
      type: 'string',
      name: 'Message',
      value: `Use of the xbe.finance website, services, dapp, or application
              is subject to the associated terms and conditions, and I hereby confirm
              that I am aware of these and accept them in full. Over and above
              the referred to terms and conditions, I specifically confirm and
              accept the following:
              1) xbe.finance is a smart contract protocol in alpha stage of launch,
                 and even though multiple security audits have been completed on the smart
                 contracts, I understand the risks associated with using the xbe.finance
                 protocol and associated functions.
              2) Any interactions that I have with the associated XBE protocol apps,
                 smart contracts or any related functions MAY place my funds at risk, and
                 I hereby release the XBE protocol and its contributors, team members,
                 service providers from any and all liability associated with my use of the
                 functions.
              3) I am lawfully permitted to access this site and use the xbe.finance
                 application functions, and I am not in contravention of any laws
                 governing my jurisdiction of residence or citizenship.`,
    },
  ];

  const handleClick = async () => {
    try {
      const provider: any = await detectEthereumProvider({
        timeout: MS_TO_DETECT_METAMASK,
      });

      if (provider) {
        handleCloseDialog();

        const sign = await provider.request({
          method: 'eth_signTypedData',
          params: [msgParams, metamaskAddress],
        });

        if (sign) {
          dispatch(meSetIsSignedAction({ userSignedData: sign }));

          setTimeout(() => {
            if (contractAction === ContractActions.depositAndStake) {
              onFundOnlyEthClick();
            } else if (contractAction === ContractActions.approve) {
              handleApproveToken();
            } else if (contractAction === ContractActions.deposit) {
              handleSubmit();
            }
          }, 1000);
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <>
      <span
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '40px',
        }}
      >
        <div className={cx(styles.BoxMiddle)}>
          <span className={cx(styles.StartButton)}> START EARNING NOW!</span>
          <div className={cx(styles.Liquidity)}>
            <span className={cx(styles.EthSpan)}>
              ADD LIQUIDITY WITH ETH ONLY
            </span>
            <div className={cx(styles.Max)}>
              <LiquidityInput
                value={valueOnlyEth}
                avail={ethBalanceFixed}
                error={isErrorInputOnlyEth}
                icon={CoinEth}
                name="amountOnlyEth"
                inputclass={styles.inputclass}
                iconclass={styles.iconclass}
                availStyle={styles.availStyle}
                margin={styles.margin}
                className={styles.inputOnlyEth}
                checked={isCheckedMaxOnlyEth}
                onChangeCheckbox={(e) => setIsCheckedMaxOnlyEth(e.target.checked)}
                onChangeInput={handleInputOnlyEth}
              />
            </div>
            <button
              className={cx(styles.StakeButton)}
              onClick={
                isSigned
                  ? onFundOnlyEthClick
                  : () => {
                    setContractAction(ContractActions.depositAndStake);
                    handleOpenDialog();
                  }
              }
              disabled
            >
              {depositOnlyEthReqStatus === RequestStatus.REQUEST
                ? 'Deposit...'
                : 'Deposit and Stake'}
            </button>
          </div>
          <span className={cx(styles.OrSpan)}>or</span>
          <form className={styles.form}>
            <div className={cx(styles.SecondLiquidity)}>
              <span className={cx(styles.SecondEthSpan)}>ADD LIQUIDITY</span>
              <span className={styles.approveDepositContainer}>
                <span className={styles.approveMobile}>
                  <span className={styles.mobileInput}>
                    <LiquidityInput
                      value={values.amountToken}
                      inputclass={styles.inputclass}
                      iconclass={styles.iconclass}
                      availStyle={styles.availStyle}
                      margin={styles.margin}
                      avail={tokenBalanceFixed}
                      error={
                        Boolean(errors.amountToken) &&
                        Boolean(touched.amountToken)
                      }
                      icon={XBE}
                      name="amountToken"
                      checked={values.isCheckedMaxToken}
                      onChangeInput={handleChangeToken}
                      buttonTextToken="XBE"
                      className={styles.input}
                      onChangeCheckbox={(e) => handleSelectMaxToken(e.target.checked)}
                    />
                  </span>
                  <button
                    type="button"
                    disabled={
                      values.amountToken.length === 0 ||
                      isValid ||
                      approveReqStatus === RequestStatus.REQUEST
                    }
                    onClick={
                      // eslint-disable-next-line no-nested-ternary
                      isNeedApproveToken && isSigned
                        ? handleApproveToken
                        : isNeedApproveToken
                          ? () => {
                            setContractAction(ContractActions.approve);
                            handleOpenDialog();
                          }
                          : undefined
                    }
                    className={cx(styles.ApproveButton)}
                  >
                    {approveReqStatus === RequestStatus.REQUEST
                      ? 'Approving...'
                      : 'Approve XBE'}
                  </button>
                </span>
                <span
                  className={styles.approveMobile}
                  style={{ marginLeft: '10px' }}
                >
                  <span className={styles.mobileInput}>
                    <LiquidityInput
                      value={values.amountEth}
                      avail={ethBalanceFixed}
                      error={
                        Boolean(errors.amountEth) && Boolean(touched.amountEth)
                      }
                      icon={CoinEth}
                      name="amountEth"
                      inputclass={styles.inputclass}
                      iconclass={styles.iconclass}
                      availStyle={styles.availStyle}
                      className={styles.input}
                      margin={styles.margin}
                      checked={values.isCheckedMaxEth}
                      onChangeCheckbox={(e) => handleSelectMaxETH(e.target.checked)}
                      onChangeInput={handleChangeETH}
                    />
                  </span>
                  <button
                    type="button"
                    disabled={isDepositButtonDisabled}
                    className={cx(styles.DepositButton)}
                    onClick={
                      isSigned
                        ? () => handleSubmit()
                        : () => {
                          setContractAction(ContractActions.deposit);
                          handleOpenDialog();
                        }
                    }
                  >
                    {depositReqStatus === RequestStatus.REQUEST
                      ? 'Processing...'
                      : 'Deposit'}
                  </button>
                </span>
              </span>
              {
                // eslint-disable-next-line max-len
                <span className={cx(styles.notetext)}>
                  {`Note - Remember to stake LP after
                   depositing, by visiting the `}
                  <a
                    href="https://alpha.app.xbe.finance/"
                    className={styles.guideLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    XBE & veXBE
                  </a>{' '}
                  page.
                </span>
              }
            </div>
          </form>
        </div>
      </span>
      <div id="myModal" className={styles.modal} ref={dialog}>
        <div className={styles.modalContent} ref={dialogContent}>
          <Text size="big" className={styles.dialogTitle}>
            Acknowledgement of Terms & Conditions of access
          </Text>
          <Text size="big" align="center" className={styles.dialogContent}>
            {`Please sign the below message (this is a once off acknowledgement
               required to use any of the XBE protocol functions) before proceeding.
                By signing this message you are acknowledging the following: `}
          </Text>
          <Text size="big" align="center" className={styles.dialogContent}>
            {`Use of the xbe.finance website, services, dapp, or application is
              subject to the associated`}
            <a
              href="https://xbe.finance/terms-and-conditions/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.guideLink}
            >
              {' '}
              terms and conditions
            </a>{' '}
            {`and I hereby confirm
              that I am aware of these and accept them in full.
              Over and above the referred to terms and conditions, I specifically
              confirm and accept the following:  `}
          </Text>
          <ul className={styles.modalList}>
            <li>
              xbe.finance is a smart contract protocol in alpha stage of launch,
              and even though multiple security{' '}
              <a
                href="https://github.com/XBEfinance/audits"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.guideLink}
              >
                {' '}
                audits
              </a>{' '}
              have been completed on the smart contracts, I understand the risks
              associated with using the xbe.finance protocol and associated
              functions.
            </li>
            <li>
              Any interactions that I have with the associated XBE protocol
              apps, smart contracts or any related functions MAY place my funds
              at risk, and I hereby release the XBE protocol and its
              contributors, team members, and service providers from any and all
              liability associated with my use of the abovementioned functions.
            </li>
            <li>
              I am lawfully permitted to access this site and use the
              xbe.finance application functions, and I am not in contravention
              of any laws governing my jurisdiction of residence or citizenship.
            </li>
          </ul>
          <Button onClick={handleClick} className={styles.gotItBtn}>
            Sign and Proceed
          </Button>
        </div>
      </div>
    </>
  );
}

export default Earnings;
