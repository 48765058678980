import createReducer from 'store/createReducer';
import { MeState } from 'store/me/types';
import { ME_HANDLERS } from 'store/me/handlers';
import { MeActionTypes } from 'store/me/actionTypes';
import { RequestStatus } from '@workstream/shared';
import { BalancesToken, Curve } from './constants';

const initialState: MeState = {
  balances: {
    [BalancesToken.XBE]: '0',
    [BalancesToken.HIVE_VAULT]: '0',
    [BalancesToken.VOTING_STAKING_REWARDS]: '0',
    [BalancesToken.SUSHI_VAULT]: '0',
    [BalancesToken.ETH_XBE]: '0',
    [BalancesToken.CVX]: '0',
    [BalancesToken.CVX_CRV]: '0',
    [BalancesToken.CRV]: '0',
  },
  eth: '0',
  usdc: '0',
  dai: '0',
  isLockedXBE: false,
  boostLevel: '0',
  boostLevelPercent: '0',
  isSigned: false,
  curveApys: {
    [Curve.EURT]: { crvBoost: '0', proj: '0' },
    [Curve.ST_ETH]: { crvBoost: '0', proj: '0' },
    [Curve.USDN]: { crvBoost: '0', proj: '0' },
    [Curve.IRONBANK]: { crvBoost: '0', proj: '0' },
    [Curve.CVX_CRV]: { crvBoost: '0', proj: '0' },
  },
  ui: {
    [MeActionTypes.GET_BALANCES]: RequestStatus.INIT,
    [MeActionTypes.GET_BALANCE_ETH]: RequestStatus.INIT,
  },
  uiBalances: {
    [BalancesToken.XBE]: RequestStatus.INIT,
    [BalancesToken.SUSHI_VAULT]: RequestStatus.INIT,
    [BalancesToken.VOTING_STAKING_REWARDS]: RequestStatus.INIT,
    [BalancesToken.HIVE_VAULT]: RequestStatus.INIT,
    [BalancesToken.ETH_XBE]: RequestStatus.INIT,
    eth: RequestStatus.INIT,
    [BalancesToken.CVX]: RequestStatus.INIT,
    [BalancesToken.CVX_CRV]: RequestStatus.INIT,
    [BalancesToken.CRV]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, ME_HANDLERS);
