/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/quotes */
import React from "react";
import DEX from 'assets/img/dextools-icon.svg';
import ETH from 'assets/img/etherscan.svg';
import SUSHI from 'assets/img/sushiswap1.svg';
import TWITTER from 'assets/img/Twitter.svg';
import DISCORD from 'assets/img/discord.svg';
import TELEGRAM from 'assets/img/telegram.svg';
import COINMARKET from 'assets/img/coin-market.svg';

import styles from './styles.module.scss';

function Logos() {
  return (
    <div className={styles.iconsWrapper}>
      <a
        href="https://www.dextools.io/app/ether/pair-explorer/0x1d46ac355f9f338d9edea6c072120abe90d67bee?utm_source=button_logos&utm_medium=easy+xbe+website&utm_content=refers%20to%20the%20section%20with%20company%20logos%20acting%20as%20buttons&utm_campaign=xbe%20easy%20web"
        target="_blank"
        rel="noreferrer"
      >
        <img className={styles.icon} src={DEX} />{' '}
      </a>
      <a
        href="https://etherscan.io/token/0x5de7cc4bcbca31c473f6d2f27825cfb09cc0bb16?utm_source=button_logos&utm_medium=easy+xbe+website&utm_content=refers%20to%20the%20section%20with%20company%20logos%20acting%20as%20buttons&utm_campaign=xbe%20easy%20web"
        target="_blank"
        rel="noreferrer"
      >
        <img className={styles.icon} src={ETH} />{' '}
      </a>
      <a
        href="https://etherscan.io/token/tokenholderchart/0x5de7cc4bcbca31c473f6d2f27825cfb09cc0bb16?utm_source=button_logos&utm_medium=easy+xbe+website&utm_content=refers%20to%20the%20section%20with%20company%20logos%20acting%20as%20buttons&utm_campaign=xbe%20easy%20web"
        target="_blank"
        rel="noreferrer"
      >
        <img className={styles.icon} src={ETH} />{' '}
      </a>
      <a
        href="https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x5de7cc4bcbca31c473f6d2f27825cfb09cc0bb16"
        target="_blank"
        rel="noreferrer"
      >
        <img className={styles.icon} src={SUSHI} />{' '}
      </a>
      <a
        href="https://twitter.com/XBEfinance?utm_source=button_logos&utm_medium=easy+xbe+website&utm_content=refers%20to%20the%20section%20with%20company%20logos%20acting%20as%20buttons&utm_campaign=xbe%20easy%20web"
        target="_blank"
        rel="noreferrer"
      >
        <img className={styles.icon} src={TWITTER} />{' '}
      </a>
      <a
        href="https://discord.com/invite/rRwwYnwtyu?utm_source=button_logos&utm_medium=easy+xbe+website&utm_content=refers%20to%20the%20section%20with%20company%20logos%20acting%20as%20buttons&utm_campaign=xbe%20easy%20web"
        target="_blank"
        rel="noreferrer"
      >
        <img className={styles.icon} src={DISCORD} />{' '}
      </a>
      <a
        href="https://t.me/xbefinance?utm_source=button_logos&utm_medium=easy+xbe+website&utm_content=refers%20to%20the%20section%20with%20company%20logos%20acting%20as%20buttons&utm_campaign=xbe%20easy%20web"
        target="_blank"
        rel="noreferrer"
      >
        <img className={styles.icon} src={TELEGRAM} />{' '}
      </a>
      <a
        href="https://coinmarketcap.com/currencies/xbe-token/?utm_source=button_logos&utm_medium=easy+xbe+website&utm_content=refers%20to%20the%20section%20with%20company%20logos%20acting%20as%20buttons&utm_campaign=xbe%20easy%20web"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        <img className={styles.icon} src={COINMARKET} />{' '}
      </a>
    </div>
  );
}

export default Logos;
