import { fork } from 'redux-saga/effects';
import { getIsLockedXbeSaga } from './getIsLockedXbe';
import meGetBalanceEthSaga from './meGetBalanceEthSaga';
import meGetBalancesSaga from './meGetBalancesSaga';
import { getBoostLevelSaga } from './getBoostLevel';
import { getCurveApysSaga } from './getCurveApys';
import { getIsSignedSaga } from './getIsSignedSaga';
import { meSignSaga } from './meSignSaga';

export default [
  fork(getIsLockedXbeSaga),
  fork(meGetBalanceEthSaga),
  fork(meGetBalancesSaga),
  fork(getBoostLevelSaga),
  fork(getCurveApysSaga),
  fork(getIsSignedSaga),
  fork(meSignSaga),
];
