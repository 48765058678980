import { put, takeEvery } from 'redux-saga/effects';
import { RequestStatus, setNotification } from '@workstream/shared';
import { apiOld } from 'utils';
import { AxiosResponse } from 'axios';
import { claimGetStakeAction, claimSetStateAction, claimSetStatusAction } from 'store/claim/actions';
import { ClaimActionTypes } from 'store/claim/actionTypes';
import { GetStakeResult } from 'store/claim/types';
// import { selectAddress } from 'store/utils';
import BigNumber from 'bignumber.js';
import { ClaimTokens } from '../constants';

function* claimGetStakeSaga(
  { type, payload: token }: ReturnType<typeof claimGetStakeAction>,
) {
  try {
    yield put(claimSetStatusAction({ type, status: RequestStatus.REQUEST }));
    // const address: string = yield selectAddress();

    let data: GetStakeResult;

    if (
      ClaimTokens.IRON_BANK === token ||
       ClaimTokens.USDN === token ||
       ClaimTokens.ST_ETH === token ||
       ClaimTokens.EURT === token
    ) {
      const correctToken =
         token === ClaimTokens.ST_ETH ? 'stEth' : token.toLowerCase();
      const { data: resultApi }: AxiosResponse<GetStakeResult[]> =
        yield apiOld.get(
          `/tokens/get-pool-info?address=0x5de7cc4bcbca31c473f6d2f27825cfb09cc0bb16&poolName=${correctToken}Vault`,
        );
      // eslint-disable-next-line prefer-destructuring
      data = resultApi[0];
    } else {
      const resultApi: AxiosResponse<GetStakeResult> = yield apiOld.get(
        `/tokens/get-claim?address=0x5de7cc4bcbca31c473f6d2f27825cfb09cc0bb16&token=${token.toUpperCase()}`,
      );
      data = resultApi.data;
    }

    yield put(claimSetStateAction({
      [token]: {
        earned: data.earned.toString(),
        deposits: data.deposits.toString(),
        apr: data.apr ? new BigNumber(data.apr).toFixed(2) : '0',
      },
    }));

    yield put(claimSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(claimSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getStakeSaga() {
  yield takeEvery(
    ClaimActionTypes.GET_STAKE,
    claimGetStakeSaga,
  );
}
